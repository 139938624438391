import React from "react";
 
const NoMatch = () => {
    return(
        <>
            <h2>ページが見つかりませんでした。</h2>
        </>
    );
};
 
export default NoMatch;